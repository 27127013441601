import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MainService } from '../api/main.service';
import EmpresaModel from '../models/empresas/empresa.model';
import UsuarioModel from '../models/usuario/usuario.model';
import { GlobalService } from '../providers/global.service';
import { NavParamsService } from '../providers/nav-params.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardGuard implements CanActivateChild {
  constructor(
    private service: MainService,
    private router: Router,
    private navParams: NavParamsService,
    private api: MainService
  ) {}

  //public isLoggedIn: boolean = false

  async canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {

      if (this.api.logged) {
        return true
      }

      try {
        let currentUser = await this.service.Login.getCurrentUser()
        if (currentUser != null) {
          //console.log(currentUser),
          this.api.usuario = new UsuarioModel(currentUser.Id, currentUser.NombreCompleto, currentUser.Apellido, currentUser.UserName, currentUser.Email, currentUser.dni, currentUser.telefono, false, currentUser.Empresa);
          //debugger;
          //console.log(this.navParams.usuario);
          this.api.logged = true;
          this.api.loginToken = currentUser.Token;
          GlobalService.publishRecargarMenu();
        }
      } catch (ex) {
          this.router.navigate(['/login-o-registro'], {
          queryParams: {
            returnURL: location.href
          }
        })
       }

      if (this.api.logged) {
        
        return true
        
      }

    this.router.navigate(['/login-o-registro'], {
      queryParams: {
        returnURL: location.href
      }
    })
    return false;
  }
  
}
