import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public static version = '0.0.3';
  public static backClicked = false;
  public static hideSplash = false;
  //public static showSplashActualiazcion = false;
  public static readyFired = false;
  public static notificationEvent: (message: any) => void;
  public static modalPresented = false;
  public static rootPages = [
    'login',
    'home'
  ];
  public static isMobile = false;

    //#region Observables

    private static RecargarMenuSubject = new Subject<void>()

    public static publishRecargarMenu(): void {
      this.RecargarMenuSubject.next()
    }

    public static getRecargarMenuObservable(): Subject<void> {
      return this.RecargarMenuSubject
    }

    //#endregion

  constructor() { }

}
