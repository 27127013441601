import { Injectable } from '@angular/core';
import { MainService } from '../main.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import ListaRequestModel from 'src/app/models/request.model.ts';
import { ListaRequestModelExtraId } from 'src/app/models/request.model.ts';
import EmpresaModel from 'src/app/models/empresas/empresa.model';
import FicheroModel from 'src/app/models/ficheros/ficheros.model';


@Injectable({
  providedIn: 'root'
})
export class EmpresasService {
  public service: MainService
  public timeoutCheckToken: any;

  constructor(public http: HttpClient) { 

  }

  public async getEmpresaByUserId(listaRequest: ListaRequestModelExtraId): Promise<EmpresaModel> {
    return await this.service.HttpPost('/empresas/postEmpresaByUserId', listaRequest)
  }

  public async subirDocumentacion(formData: FormData): Promise<FicheroModel> {
    return await this.service.HttpPostConFormData('/empresas/subirDocumentacion', formData)
  }

  public async deleteDocumento(fichero : FicheroModel): Promise<boolean> {
    return await this.service.HttpPost('/empresas/deleteDocumento', fichero)
  }

}
