import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    public toastCtrl: ToastController,
    public loaderService: LoaderService
  ) { }

  showToast(message: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.loaderService.hideAll();
      this.toastCtrl.create({
        message,
        duration: 4000,
        position: "top",
        color: "light"
      }).then((toast) => {
        toast.removeAttribute('tabindex');
        toast.present();
        setTimeout(() => {
          resolve(); // resolvemos cuando se oculta el toast.
        }, 4500);
      });
    });
  }


  showToastError(message: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.loaderService.hideAll();
      this.toastCtrl.create({
        message,
        duration: 4000,
        position: "top",
        color: "light"
      }).then((toast) => {
        toast.removeAttribute('tabindex');
        toast.present();
        setTimeout(() => {
          resolve(); // resolvemos cuando se oculta el toast.
        }, 4500);
      });
    });
  }

  showToastButtonClose(message: string, color: "oscuro" | "warning" | "danger"): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.loaderService.hideAll();
      this.toastCtrl.create({
        message,
        position: "top",
        color: color,
        buttons: [
          {
            side: 'end',
            icon: 'close',
            text: '',
            role: 'cancel',
            handler: () => {
              resolve(false);
            }
          }
        ]
      }).then((toast) => {
        toast.removeAttribute('tabindex');
        toast.present();
      });
    });
  }

  showToastButtonCloseAndButtonAction(message: string, color: "oscuro" | "warning" | "danger", buttonActionText?: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let myButtonActionText: string = "Ir";
      if (buttonActionText != undefined && buttonActionText != null && buttonActionText.length > 0) {
        myButtonActionText = buttonActionText;
      }
      this.loaderService.hideAll();
      this.toastCtrl.create({
        message: "",
        position: "top",
        color: color,
        cssClass: "toastButton",
        buttons: [
          {
            side: 'start',
            //icon: 'arrow-forward-outline',
            text: `${message} ${myButtonActionText}`,
            handler: () => {
              resolve(true);
            }
          },
          {
            side: 'end',
            icon: 'close',
            text: '',
            role: 'cancel',
            handler: () => {
              resolve(false);
            }
          }
        ]
      }).then((toast) => {
        toast.removeAttribute('tabindex');
        toast.present();
        // setTimeout(() => {
        //   resolve(); // resolvemos
        // }, 500);
      });
    });
  }

  // showToastOk(message: string): Promise<void> {
  //   return new Promise<void>((resolve, reject) => {
  //     this.hideAllLoadings();
  //     this.toastCtrl.create({
  //       message,
  //       duration: 4000,
  //       position: "top",
  //       color: "success"
  //     }).then((toast) => {
  // toast.removeAttribute('tabindex');
  //       toast.present();
  //       setTimeout(() => {
  //         resolve(); // resolvemos cuando se oculta el toast.
  //       }, 4500);
  //     });
  //   });
  // }

  // showToastAlert(message: string): Promise<void> {
  //   return new Promise<void>((resolve, reject) => {
  //     this.hideAllLoadings();
  //     this.toastCtrl.create({
  //       message,
  //       duration: 4000,
  //       position: "top",
  //       color: "warning"
  //     }).then((toast) => {
    // toast.removeAttribute('tabindex');
  //       toast.present();
  //       setTimeout(() => {
  //         resolve(); // resolvemos cuando se oculta el toast.
  //       }, 4500);
  //     });
  //   });
  // }

}
