import { Injectable } from "@angular/core";
import { AlertController, LoadingController, ToastController } from "@ionic/angular";
import { Subject } from "rxjs";
import { ConfigService } from "./config.service";

@Injectable({
    providedIn: "root",
})
export class LoaderService {

    private loaders: any[]
    public isLoading: Subject<boolean>;
    public loaderText: string;

    constructor(
        public configService: ConfigService,
    ) {
        this.loaders = [];
        this.isLoading = new Subject<boolean>();
        this.loaderText = 'Cargando...';
    }

    show(msg?: string): Promise<void> {
        this.loaders.push(`loader${this.loaders.push.length + 1}`)
        this.loaderText = (msg != undefined && msg != null && msg.trim().length > 0 ? msg : 'Cargando...');
        this.isLoading.next((this.loaders.length > 0));
        return;
    }

    hide(): Promise<void> {
        if (this.loaders.push.length > 0) this.loaders.pop();
        this.isLoading.next((this.loaders.length > 0));
        return;
    }

    hideAll(): Promise<void> {
        this.loaders = [];
        this.isLoading.next(false);
        return;
    }

}