import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import PagingModel from 'src/app/models/general/paging.model';
import { ItemRequestModel } from 'src/app/models/request.model.ts';
import { ListaRequestModelExtraId } from 'src/app/models/request.model.ts';
import ResultClass from 'src/app/models/general/result';
import ProvinciaModel from 'src/app/models/geo/provincia.model';
import { GlobalService } from 'src/app/providers/global.service';
import { UtilService } from 'src/app/providers/util.service';
import { MainService } from '../main.service';
import ComboModel from 'src/app/models/general/combo.model';
import ZonasModel from 'src/app/models/zonas/zonas.model';
import PoblacionModel from 'src/app/models/geo/poblacion.model';

@Injectable({
  providedIn: 'root'
})
export class GeoService {

  public service: MainService;

  public zonas: ComboModel[] = [];

  constructor(public http: HttpClient) { }
  
  //#region Provincias

  public async getProvincias(filtroBusqueda: string): Promise<ProvinciaModel[]> {
    return await this.service.HttpPost('/geo/getProvincias', {
      start: 0,
      limit: 0,
      extraId: '',
      filtroBusqueda: filtroBusqueda
    })   
  }

  public async getProvincia(id: number): Promise<ProvinciaModel> {
    return await this.service.HttpPost('/geo/getProvinciaById', {
      id
    });
  }

  public async getProvinciaByCP(id: number): Promise<ProvinciaModel> {
    return await this.service.HttpPost('/geo/getProvinciaByCP', {
      id
    });
  }

  public async getZonas(filtroBusqueda : string,  extraId: string): Promise<ZonasModel[]> {
    
    return await this.service.HttpPost('/geo/getZonas', {
      start: 0,
      limit: 0,
      extraId: extraId,
      filtroBusqueda: filtroBusqueda
    })    
  }

  public async getZona(id: number): Promise<ZonasModel> {
    return await this.service.HttpPost('/geo/getZonaById', {
      id
    });
  }

  public async getZonaByZonaIdAndContenedorId(extraId: string, filtroBusqueda: string): Promise<ZonasModel> {
    return await this.service.HttpPost('/geo/getZonaByZonaIdAndContenedorId', {
      extraId : extraId,
      filtroBusqueda: filtroBusqueda
    });
  }

  public async getMunicipiosByProvincia(filtroBusqueda: string, provinciaId: string): Promise<PoblacionModel[]> {
    return await this.service.HttpPost('/geo/getMunicipiosByProvincia', {
      start: 0,
      limit: 0,
      extraId: provinciaId,
      filtroBusqueda: filtroBusqueda
    })   
  }

  //#endregion
 
}
