import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse, HttpHandler } from '@angular/common/http';
import ResultClass from '../models/general/result';
import { LoginService } from './login/login.service';
import UsuarioModel from '../models/usuario/usuario.model';
import { WebPushService } from './web-push/web-push.service';
import { TarifasService } from './tarifas/tarifas.service';
import { FacturasService } from './facturas/facturas.service';
import { ContratosService } from './contratos/contratos.service';
import { EmpresasService } from './empresas/empresas.service';
import { UsuariosService } from './usuarios/usuarios.service';
import LoginResultModel from '../models/login/loginResult.model';
import { SolicitudesService } from './solicitudes/solicitudes.service';
import { GeoService } from './geo/geo.service';
import { utils } from 'protractor';
import { UtilService } from '../providers/util.service';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  public URL: string;
  public loginToken: string;
  public logged: boolean;
  public usuario: UsuarioModel | null;
  public categoriaId: number;
  public usuarioLogin: LoginResultModel;
  public vinculacionEmpresa: boolean;

  constructor(
    public http: HttpClient,
    public Login: LoginService, 
    public httpHandler: HttpHandler,
    public WebPush: WebPushService,
    public tarifas: TarifasService,
    public facturas: FacturasService,
    public contratos: ContratosService,
    public empresas: EmpresasService,
    public usuarios: UsuariosService,
    public solicitudes: SolicitudesService,
    public Geo: GeoService
  ) { 

    this.Login.service = this;
    this.WebPush.api = this;
    this.tarifas.service = this;
    this.facturas.service = this;
    this.contratos.service = this;
    this.empresas.service = this;
    this.usuarios.service = this;
    this.solicitudes.service = this;
    this.Geo.service = this;

    // Importante distinguir entre environment.ts y environment.prod.ts
    // environment.ts aplica al hacer ionic serve, environment.prod.ts aplica al compilar con ng build
    // o hacer Deploy en Ionic
    this.URL = environment.serviceURL
    if (localStorage.getItem('loginToken') != null && localStorage.getItem('loginToken') != '') {
      this.loginToken = localStorage.getItem('loginToken')
    }

  }

  public async HttpGet<T>(url: string, params?: any, baseUrl?: string, checkLogin?: boolean): Promise<T> {
    return new Promise<T>(async (resolve, reject) => {
      let headers = new HttpHeaders()
      headers = headers.append('Content-Type', 'application/json')
      
      if (this.loginToken != null) {
        headers = headers.append('Access-Token', this.loginToken)
      }

      if (baseUrl == null) baseUrl = this.URL;

      params = params || {}
      let httpParams = new HttpParams()
      for (let param in params) {
        if (params[param] != null) {
          httpParams = httpParams.append(param, params[param].toString())
        } else {
          httpParams = httpParams.append(param, '')
        }
      }

      const options = {
        headers,
        withCredentials: true,
        params: httpParams
      }
      
      this.http.get<ResultClass<T>>(baseUrl + url, options).subscribe(data => {
        if (data.Error && data.Error.HasError) {
          reject(new Error(data.Error.Message))
        } else {
          resolve(data.content)
        }
      }, (err: HttpErrorResponse) => {
        if (err != null && err.error != null && err.error.Error != null && err.error.Error.Message != null) {
          reject(new Error(err.error.Error.Message))
        } else {
          reject(new Error('Se ha producido un error.'))
        }
      })
   
    })
  }

  public async HttpPost<T>(url: string, params?: any, baseUrl?: string, checkLogin?: boolean): Promise<T> {
    return new Promise<T>(async (resolve, reject) => {

      let headers = new HttpHeaders()
      headers = headers.append('Content-Type', 'application/json')
      
      if (this.loginToken != null) {
        headers = headers.append('Access-Token', this.loginToken)
      }

      if (baseUrl == null) baseUrl = this.URL;

      params = params || {}
      const options = {
        headers,
        withCredentials: true
      }

      this.http = new HttpClient(this.httpHandler);

      this.http.post<ResultClass<T>>(baseUrl + url, JSON.stringify(params), options).subscribe(data => {
        if (data.Error && data.Error.HasError) {
          reject(new Error(data.Error.Message))
        } else {
          resolve(data.content)
        }
      }, (err: HttpErrorResponse) => {
        if (err != null && err.error != null && err.error.Error != null && err.error.Error.Message != null) {
          reject(new Error(err.error.Error.Message))
        } else {
          reject(new Error('Se ha producido un error.'))
        }
      })

    })
  }

  public HttpPostConFormData<T>(url: string, params: FormData, baseUrl?: string): Promise<T> {
    return new Promise<T>(async (resolve, reject) => {
      let headers = new HttpHeaders()

      if (this.loginToken != null) {
        headers = headers.append('Access-Token', this.loginToken)
      }

      if (UtilService.isNullOrUndefined(baseUrl)) baseUrl = this.URL;

      const body = params;
      const options = {
        headers,
        withCredentials: true
      }

      this.http = new HttpClient(this.httpHandler);
      this.http.post<ResultClass<any>>(baseUrl + url, body, options).subscribe(async data => {
        if (data.Error && data.Error.HasError) {
          reject(new Error(data.Error.Message))
        } else {
          resolve(data.content)
        }
      }, (err: HttpErrorResponse) => {
        if (err != null && err.error != null && err.error.Error != null && err.error.Error.Message != null) {
          reject(new Error(err.error.Error.Message))
        } else {
          reject(new Error('Se ha producido un error.'))
        }
      })

    })
  }

  public async HttpPut<T>(url: string, params?: any, baseUrl?: string, checkLogin?: boolean): Promise<T> {
    return new Promise<T>(async (resolve, reject) => {

      let headers = new HttpHeaders()
      headers = headers.append('Content-Type', 'application/json')
      
      if (this.loginToken != null) {
        headers = headers.append('Access-Token', this.loginToken)
      }

      if (baseUrl == null) baseUrl = this.URL;

      params = params || {}
      const options = {
        headers,
        withCredentials: true
      }

      this.http.put<ResultClass<T>>(baseUrl + url, JSON.stringify(params), options).subscribe(data => {
        if (data.Error && data.Error.HasError) {
          reject(new Error(data.Error.Message))
        } else {
          resolve(data.content)
        }
      }, (err: HttpErrorResponse) => {
        if (err != null && err.error != null && err.error.Error != null && err.error.Error.Message != null) {
          reject(new Error(err.error.Error.Message))
        } else {
          reject(new Error('Se ha producido un error.'))
        }
      })

    })
  }

  public async HttpPutFormData<T>(url: string, formData: FormData, baseUrl?: string, checkLogin?: boolean): Promise<T> {
    return new Promise<T>(async (resolve, reject) => {

      let headers = new HttpHeaders()
      
      if (this.loginToken != null) {
        headers = headers.append('Access-Token', this.loginToken)
      }

      if (baseUrl == null) baseUrl = this.URL;

      const options = {
        headers,
        withCredentials: true
      }

      this.http.put<ResultClass<T>>(baseUrl + url, formData, options).subscribe(data => {
        if (data.Error && data.Error.HasError) {
          reject(new Error(data.Error.Message))
        } else {
          resolve(data.content)
        }
      }, (err: HttpErrorResponse) => {
        if (err != null && err.error != null && err.error.Error != null && err.error.Error.Message != null) {
          reject(new Error(err.error.Error.Message))
        } else {
          reject(new Error('Se ha producido un error.'))
        }
      })

    })
  }

  public async HttpDelete<T>(url: string, params?: any, baseUrl?: string, checkLogin?: boolean): Promise<T> {
    return new Promise<T>(async (resolve, reject) => {

      let headers = new HttpHeaders()
      headers = headers.append('Content-Type', 'application/json')
      
      if (this.loginToken != null) {
        headers = headers.append('Access-Token', this.loginToken)
      }

      if (baseUrl == null) baseUrl = this.URL;

      params = params || {}
      let httpParams = new HttpParams()
      for (let param in params) {
        if (params[param] != null) {
          httpParams = httpParams.append(param, params[param].toString())
        } else {
          httpParams = httpParams.append(param, '')
        }
      }

      const options = {
        headers,
        withCredentials: true,
        params: httpParams
      }
      
      this.http.delete<ResultClass<T>>(baseUrl + url, options).subscribe(data => {
        if (data.Error && data.Error.HasError) {
          reject(new Error(data.Error.Message))
        } else {
          resolve(data.content)
        }
      }, (err: HttpErrorResponse) => {
        if (err != null && err.error != null && err.error.Error != null && err.error.Error.Message != null) {
          reject(new Error(err.error.Error.Message))
        } else {
          reject(new Error('Se ha producido un error.'))
        }
      })
   
    })
  }  
}
