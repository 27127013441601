import { Injectable } from '@angular/core';
import { MainService } from '../main.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import FacturasModel from 'src/app/models/facturas/facturas.model';
import ListaRequestModel from 'src/app/models/request.model.ts';
import { ListaRequestModelExtraId } from 'src/app/models/request.model.ts';
import { LineasFacturaModel } from 'src/app/models/facturas/facturas.model';

@Injectable({
  providedIn: 'root'
})
export class FacturasService {
  public service: MainService
  public timeoutCheckToken: any;

  constructor(public http: HttpClient) { 

  }

  public async getAllFacturasByEmpresaId(listaRequest: ListaRequestModelExtraId): Promise<FacturasModel[]> {
    return await this.service.HttpPost('/facturas/postFacturasByEmpresaId', listaRequest)
  }

  public async getAllLineasFacturaByFacturaId(factura: FacturasModel): Promise<LineasFacturaModel[]> {
    return await this.service.HttpPost('/facturas/postLineasFacturaByFacturaId', factura)
  }

}
