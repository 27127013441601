import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import UsuarioModel from 'src/app/models/usuario/usuario.model';
import { GlobalService } from 'src/app/providers/global.service';
import { MainService } from '../main.service';
import PagingModel from 'src/app/models/general/paging.model';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  public service: MainService;

  constructor(
    public http: HttpClient
  ) { }

  public async getUsuario(id: string): Promise<UsuarioModel> {
    return await this.service.HttpPost('/usuarios/getUsuario', {
      id
    })
  }

  public getAllUsuarios(start: number, limit: number, empresaId, filtro_Busqueda: string): Promise<PagingModel<UsuarioModel>> {
    return this.service.HttpGet('/usuarios/getUsuariosByEmpresaId', {start, limit, empresaId, filtro_Busqueda});
  }

  public saveUsuario(usuario: UsuarioModel): Promise<UsuarioModel> {
    return this.service.HttpPost('/usuarios/postsaveusuario', usuario);
  }

  public async postGuardarBloqueoUsuario(usuario: UsuarioModel): Promise<boolean> {
    return await this.service.HttpPost('/usuarios/postGuardarBloqueoUsuario', usuario);
  }

}



