import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-tabs-public',
  templateUrl: './tabs-public.component.html',
  styleUrls: ['./tabs-public.component.scss'],
})
export class TabsPublicComponent implements OnInit {

  @Input() current: string;
  constructor(
    public navCtrl: NavController,
  ) {    
    if (this.current == undefined || this.current == null || this.current.trim().length == 0)  {
      this.current = 'home';
    }
  }

  ngOnInit() {
    
  }

  changeTab() {
    this.navCtrl.navigateRoot(this.current)
  }

}
