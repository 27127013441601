import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthguardGuard } from './guards/authguard.guard';
import { UsuariosFormResolver } from './pages/usuarios-form/usuarios-form.resolver';
import { ServiciosContratadosDetalleResolver } from './pages/servicios-contratados-detalle/servicios-contratados-detalle.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '',
    canActivateChild: [AuthguardGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
      },
      {
        path: 'tarifas-nuevo-servicio-detalle',
        loadChildren: () => import('./pages/tarifas-nuevo-servicio-detalle/tarifas-nuevo-servicio-detalle.module').then( m => m.TarifasNuevoServicioDetallePageModule)
      },
      {
        path: 'facturas-listado',
        loadChildren: () => import('./pages/facturas-listado/facturas-listado.module').then( m => m.FacturasListadoPageModule)
      },
      {
        path: 'servicios-contratados-listado',
        loadChildren: () => import('./pages/servicios-contratados-listado/servicios-contratados-listado.module').then( m => m.ServiciosContratadosListadoPageModule)
      },
      {
        path: 'facturas-detalle',
        loadChildren: () => import('./pages/facturas-detalle/facturas-detalle.module').then( m => m.FacturasDetallePageModule)
      },
      {
        path: 'tarifas-nuevo-servicio-detalle-modal',
        loadChildren: () => import('./pages/tarifas-nuevo-servicio-detalle-modal/tarifas-nuevo-servicio-detalle-modal.module').then( m => m.TarifasNuevoServicioDetalleModalPageModule)
      },
      {
        path: 'solicitudes-listado',
        loadChildren: () => import('./pages/solicitudes-listado/solicitudes-listado.module').then( m => m.SolicitudesListadoPageModule)
      },
      {
        path: 'servicios-contratados-detalle/:id',
        loadChildren: () => import('./pages/servicios-contratados-detalle/servicios-contratados-detalle.module').then( m => m.ServiciosContratadosDetallePageModule),
        resolve: {
          solicitudContrato: ServiciosContratadosDetalleResolver,
        },
      },
      {
        path: 'terminos-condiciones',
        loadChildren: () => import('./pages/terminos-condiciones/terminos-condiciones.module').then( m => m.TerminosCondicionesPageModule)
      },
      {
        path: 'ayuda-soporte',
        loadChildren: () => import('./pages/ayuda-soporte/ayuda-soporte.module').then( m => m.AyudaSoportePageModule)
      },
      {
        path: 'contacto',
        loadChildren: () => import('./pages/contacto/contacto.module').then( m => m.ContactoPageModule)
      },
      {
        path: 'politicas-privacidad',
        loadChildren: () => import('./pages/politicas-privacidad/politicas-privacidad.module').then( m => m.PoliticasPrivacidadPageModule)
      },
      {
        path: 'mi-empresa',
        loadChildren: () => import('./pages/mi-empresa/mi-empresa.module').then( m => m.MiEmpresaPageModule)
      },
      {
        path: 'mis-usuarios',
        loadChildren: () => import('./pages/mis-usuarios/mis-usuarios.module').then( m => m.MisUsuariosPageModule)
      },
      {
        path: 'usuarios-form/:id',
        loadChildren: () => import('./pages/usuarios-form/usuarios-form.module').then( m => m.UsuariosFormPageModule),
        resolve: {
          usuario: UsuariosFormResolver,
        },
      }
    ]
  },
  {
    //paginas sin autenticación
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'login-animacion',
    loadChildren: () => import('./pages/login-animacion/login-animacion.module').then( m => m.LoginAnimacionPageModule)
  },
  {
    path: 'login-o-registro',
    loadChildren: () => import('./pages/login-o-registro/login-o-registro.module').then( m => m.LoginORegistroPageModule)
  },{
    path: 'registrarse',
    loadChildren: () => import('./pages/registrarse/registrarse.module').then( m => m.RegistrarsePageModule)
  },
  {
    path: 'recuperar-password-modal',
    loadChildren: () => import('./pages/recuperar-password-modal/recuperar-password-modal.module').then( m => m.RecuperarPasswordModalPageModule)
  },
  {
    path: 'nueva-password',
    loadChildren: () => import('./pages/nueva-password/nueva-password.module').then( m => m.NuevaPasswordPageModule)
  },
  {
    path: 'politicas-privacidad-modal',
    loadChildren: () => import('./pages/politicas-privacidad-modal/politicas-privacidad-modal.module').then( m => m.PoliticasPrivacidadModalPageModule)
  },
  {
    path: 'error-login-modal',
    loadChildren: () => import('./pages/error-login-modal/error-login-modal.module').then( m => m.ErrorLoginModalPageModule)
  },
  {
    path: 'buscador-provincias-modal',
    loadChildren: () => import('./pages/buscador-provincias-modal/buscador-provincias-modal.module').then( m => m.BuscadorProvinciasModalPageModule)
  },
  {
    path: 'buscador-poblaciones-modal',
    loadChildren: () => import('./pages/buscador-poblaciones-modal/buscador-poblaciones-modal.module').then( m => m.BuscadorPoblacionesModalPageModule)
  },
  {
    path: 'tarifas-listado/:tipo',
    loadChildren: () => import('./pages/tarifas-listado/tarifas-listado.module').then( m => m.TarifasListadoPageModule)
  },
  {
    path: 'tarifas-detalle',
    loadChildren: () => import('./pages/tarifas-detalle/tarifas-detalle.module').then( m => m.TarifasDetallePageModule)
  },
  {
    path: 'tarifas-detalle-modal',
    loadChildren: () => import('./pages/tarifas-detalle-modal/tarifas-detalle-modal.module').then( m => m.TarifasDetalleModalPageModule)
  },
  {
    path: 'tarifas-estimaciones',
    loadChildren: () => import('./pages/tarifas-estimaciones/tarifas-estimaciones.module').then( m => m.TarifasEstimacionesPageModule)
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
