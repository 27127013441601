import { Injectable } from '@angular/core';
import { MainService } from '../main.service';
import LoginResult from '../../models/login/loginResult.model';
import { NavParamsService } from 'src/app/providers/nav-params.service';
import RegisterModel from 'src/app/models/register/register.model';
import { GlobalService } from 'src/app/providers/global.service';
import UsuarioModel from 'src/app/models/usuario/usuario.model';
import ValidateRecoverySignatureRequestModel from 'src/app/models/general/ValidateRecoverySignatureRequest.model';
import { ModalService } from 'src/app/providers/modal.service';
import { ErrorLoginModalPage } from 'src/app/pages/error-login-modal/error-login-modal.page';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public service: MainService
  public timeoutCheckToken: any;

  constructor(private navParams: NavParamsService, private modalService: ModalService) { }

  public desactivarCheckToken() {
    if (this.timeoutCheckToken != null) {
      clearInterval(this.timeoutCheckToken);
    }
  }

  public async loginWithToken(): Promise<LoginResult> {
    let resp: LoginResult = await this.service.HttpPost('/login/loginToken');
    let doLogin = await this.doLoginUsuario(resp);
    if (doLogin) {
      return resp;
    } else {
      return
    }
  }

  private async doLoginUsuario(resp: LoginResult): Promise<boolean> {
    try {
      if (resp && resp.userId.trim().length > 0) {
        await this.service.Login.setLogin(resp.Token);
        await this.getDatosUsuario(resp.userId);
        GlobalService.publishRecargarMenu();
        return (this.service.logged && this.service.usuario != null && this.service.usuario.userId == resp.userId);
      } else {
        return false;
      }
    } catch (error) {
      throw (new Error(error.message))
    }
  }

  public async login(UserName: string, Password: string): Promise<LoginResult> {
    
    try {
      let resp: LoginResult = await this.service.HttpPost('/login/PostLogin', {
        UserName: UserName,
        Password: Password
      });
      let doLogin = await this.doLoginUsuario(resp);
      if (doLogin) {
        return resp;
      } else {
        return 
      }
    } catch (error) {
      
      //let resp = await this.modalService.showModal(ErrorLoginModalPage, true);
    }
    
  }

  //Login temporal para diseño
  /*public async login(UserName: string, Password: string): Promise<LoginResult> {
    return await this.service.HttpPost<LoginResult>('/login', {
      UserName: UserName,
      Password: Password
    })
  }*/

  public async getCurrentUser(): Promise<LoginResult> {
    if(localStorage.getItem('loginToken') != null && localStorage.getItem('loginToken') != '') {
      this.service.loginToken = localStorage.getItem('loginToken')
    }
    return await this.service.HttpGet<LoginResult>('/login/getCurrentUser')
  }

  public async getCurrentUserTarifas(): Promise<LoginResult> {
    if(localStorage.getItem('loginToken') != null && localStorage.getItem('loginToken') != '') {
      this.service.loginToken = localStorage.getItem('loginToken')
    }
    return await this.service.HttpGet<LoginResult>('/login/getCurrentUserTarifas')
  }

  /*public async getDatosUsuario(userId: string): Promise<void> {
    this.service.usuario = await this.service.usuarios.getUsuario(userId);
    return
  }*/

  public async getDatosUsuario(userId: string): Promise<void> {
    let currentUser = await this.service.Login.getCurrentUser()
        if (currentUser != null) {
          //console.log(currentUser),
          this.service.usuario = new UsuarioModel(currentUser.Id, currentUser.NombreCompleto, currentUser.Apellido, currentUser.UserName, currentUser.Email, currentUser.dni, currentUser.telefono, false, currentUser.Empresa);
          //debugger;
          //console.log(this.navParams.usuario);
          this.service.logged = true;
          this.service.loginToken = currentUser.Token;
        }
    return
  }

  public async logOut(removePushToken: boolean): Promise<void> {
    this.service.usuario = null;
    this.service.loginToken = null;
    this.service.logged = false;
    localStorage.removeItem('loginToken');
    //if (clearDni) localStorage.removeItem('dni');
    if (removePushToken) {
      let pushToken = window["pushToken"]
      if (pushToken != null) {
        try {
          await this.service.WebPush.removeToken(pushToken)
        } catch (ex) {
          console.log('No se pudo eliminar el token de notificaciones PUSH porque el servicio devolvió error.')
        }
      }
    }
    this.navParams.clear();
    this.desactivarCheckToken();
    return
  }

  private setLogin(token: string): Promise<void> {
    try {
      this.service.loginToken = token;
      this.service.logged = true;
      localStorage.setItem('loginToken', token);
    } catch (ex) {
      throw (new Error(ex.message))
    } finally {
      return;
    }
  }

  public async register(registro: RegisterModel): Promise<LoginResult> {
    try{
      return await this.service.HttpPost('/login/register', registro);
    } catch (error) {
      throw new Error(error.message)
    }
    
  }

  public async Recovery(email: string) {
    return this.service.HttpGet('/login/getrecoverpassword', {email});
  }

  public async validateRecoverySignature(recoveryPassw: ValidateRecoverySignatureRequestModel): Promise<boolean> {
    return await this.service.HttpPost('/login/validaterecoverysignature', recoveryPassw); 
  }

  public async generarContrasenia(datosUsuario: RegisterModel): Promise<boolean> {
    return await this.service.HttpPost('/login/generarcontrasenia', datosUsuario); 
  }

  public saveData(usuario: UsuarioModel): Promise<UsuarioModel> {
    return this.service.HttpPost('/login/postsavedata', usuario);
  }

}
