import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CalendarComponent } from './calendar/calendar.component';
import { FvCalendarComponent } from './fv-calendar/fv-calendar.component';
import { ModalComponent } from './modal/modal.component';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader/loader.component';
import { OrientationComponent } from './orientation/orientation.component';
import { TabsPublicComponent } from './tabs-public/tabs-public.component';
import { TabsPrivateComponent } from './tabs-private/tabs-private.component';
import { RouterModule } from '@angular/router';



@NgModule({
    declarations: [
      TabsPublicComponent,
      TabsPrivateComponent,
      ModalComponent,
      LoaderComponent,
      FvCalendarComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        RouterModule
    ],
    exports: [
      TabsPublicComponent,
      TabsPrivateComponent,
      ModalComponent,
      LoaderComponent,
      FvCalendarComponent
    ]
})

export class ComponentsModule { }
