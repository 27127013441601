import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { MainService } from "src/app/api/main.service";
import EmpresaModel from "src/app/models/empresas/empresa.model";
import UsuarioModel from "src/app/models/usuario/usuario.model";
import { AlertService } from "src/app/providers/alert.service";
import { LoaderService } from "src/app/providers/loader.service";

@Injectable({
    providedIn: 'root'
})
export class UsuariosFormResolver implements Resolve<UsuarioModel> {
    constructor(
        public api: MainService,
        private alertService: AlertService,
        private loaderService: LoaderService
    ) { }

    public async resolve(route: ActivatedRouteSnapshot): Promise<UsuarioModel> {
        try {
            await this.loaderService.show('Cargando...');
            let id = route.paramMap.get('id') || ''
            if (id != null && id != "" && id!="new") {
                return await this.api.usuarios.getUsuario(id)
            } else {
                return new UsuarioModel('', '', '', '', '', '', '',false, null)
            }
        } catch (ex: any) {
            if (ex != null && ex.message != null) {
                this.alertService.showMessage(ex.message, '¡Atención!')
            }
            throw ex
        } finally {
            await this.loaderService.hide()
        }
    }
}
