import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment'
import * as _ from 'lodash'
import dateObj from 'src/app/models/general/dateObj';
import singularDate from 'src/app/models/general/singularDate';

// import { GlobalesService } from 'src/app/providers/globales.service';

@Component({
  selector: 'app-fv-calendar',
  templateUrl: './fv-calendar.component.html',
  styleUrls: ['./fv-calendar.component.scss'],
})
export class FvCalendarComponent implements OnInit {

  @Output() public onDaySelect = new EventEmitter<dateObj>();
  @Output() public onMonthSelect = new EventEmitter<any>();
  @Input() public events: Array<singularDate> = [];
  @Input() public lang: string;
  @Input() public dateSelected: Date;
  @Input() public startDate: Date;
  @Input() public endDate: Date;

  public allowBack: boolean = true;
  public allowForward: boolean = true;

  public currentYear: number = moment().year();
  public currentMonth: number = moment().month();
  public currentDate: number = moment().date();
  public currentDay: number = moment().day();

  public displayYear: number = moment().year();
  public displayMonth: number = moment().month();

  public dateArray: Array<dateObj> = []; // Array for all the days of the month
  public weekArray = []; // Array for each row of the calendar
  public lastSelect: number = 0; // Record the last clicked location

  public weekHead: string[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  constructor() {
    this.today();
    this.createMonth(this.displayYear, this.displayMonth);
  }

  public ngOnInit() {}

  public ngOnChanges() {
    this.displayYear = this.dateSelected != null ? this.dateSelected.getFullYear() : this.displayYear;
    this.displayMonth = this.dateSelected != null ? this.dateSelected.getMonth() : this.displayMonth;
    this.createMonth(this.displayYear, this.displayMonth);
  }

  public ngAfterContentInit() {
    // if (!this.lang) { this.lang = 'en'; }
    // if (this.lang === 'es') {
    //   this.weekHead = ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'];
    // }
    
    // if (GlobalesService.language == "es") {
    //     this.lang = "es";
    //     this.weekHead = ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'];
    // } else if (GlobalesService.language == "en") {
    //     this.lang = "en";
    //     this.weekHead = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    // } else {
    //   this.lang = "es";
    //   this.weekHead = ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'];
    // }
    
    this.weekHead = ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'];

  }

  // Jump to today
  public today() {
      this.displayYear = this.currentYear;
      this.displayMonth = this.currentMonth;
      this.createMonth(this.currentYear, this.currentMonth);

      // Mark today as a selection
      let todayIndex = _.findIndex(this.dateArray, {
          year: this.currentYear,
          month: this.currentMonth,
          date: this.currentDate,
          isThisMonth: true
      })
      this.lastSelect = todayIndex;
      this.dateArray[todayIndex].isSelect = true;

      this.onDaySelect.emit(this.dateArray[todayIndex]);
  }

  public isInEvents(year, month, date) {
    var i=0, len=this.events != undefined ? this.events.length : 0;
    for (; i<len; i++) {
      if (this.events[i].year == year && this.events[i].month == month && this.events[i].date == date) {
        return true;
      }
    }
    return false;
  }

  public getEventClass(year, month, date) {
    var i=0, len=this.events.length;
    for(; i<len; i++) {
      if (this.events[i].year == year && this.events[i].month == month && this.events[i].date == date) {
        return this.events[i].eventClass;
      }
    }
    return '';
  }

  public createMonth(year: number, month: number) {
      
    this.dateArray = []; // Clear last month's data
    this.weekArray = []; // Clear week data

    let firstDay;
    // The day of the week on the first day of the current month of
    // selection determines how many days to take out last month. Sunday
    // does not show last month, Monday shows the previous month, Tuesday
    // shows the last two days

    let preMonthDays; // The number of days for the previous month
    let monthDays; // The number of days for the month
    let weekDays: Array<dateObj> = [];

    firstDay = moment({ year: year, month: month, date: 1 }).day();
    // The number of days last month
    if (month === 0) {
        preMonthDays = moment({ year: year - 1, month: 11 }).daysInMonth();
    } else {
        preMonthDays = moment({ year: year, month: month - 1 }).daysInMonth();
    }
    // The number of days this month
    monthDays = moment({ year: year, month: month }).daysInMonth();

    // PREVIOUS MONTH
    // Add the last few days of the previous month to the array
    if (firstDay !== 1) { // Monday doesn't need to be shown for the previous month
        let lastMonthStart = preMonthDays - firstDay + 1; // From the last few months start
        if (firstDay == 0) {
            lastMonthStart -= 7;
        }
        for (let i = 1; i < (firstDay == 0 ? 7 : firstDay); i++) {
            if (month === 0) {
                this.dateArray.push({
                    year: year - 1,
                    month: 11,
                    date: lastMonthStart + i,
                    isThisMonth: false,
                    isToday: false,
                    isSelect: false,
                    hasEvent: (this.isInEvents(year, 11, lastMonthStart+i)) ? true : false,
                    eventClass: (this.isInEvents(year, 11, lastMonthStart+i)) ? this.getEventClass(year - 1, 11, lastMonthStart+i) : ''
                })
            } else {
                this.dateArray.push({
                    year: year,
                    month: month - 1,
                    date: lastMonthStart + i,
                    isThisMonth: false,
                    isToday: false,
                    isSelect: false,
                    hasEvent: (this.isInEvents(year, month-1, lastMonthStart+i)) ? true : false,
                    eventClass: (this.isInEvents(year, month-1, lastMonthStart+i)) ? this.getEventClass(year, month-1, lastMonthStart+i) : ''
                })
            }

        }
    }

    // Add the numeral for this month to the array
    for (let i = 0; i < monthDays; i++) {
        this.dateArray.push({
            year: year,
            month: month,
            date: i + 1,
            isThisMonth: true,
            isToday: false,
            isSelect: false,
            hasEvent: (this.isInEvents(year, month, i+1)) ? true : false,
            eventClass: (this.isInEvents(year, month, i+1)) ? this.getEventClass(year, month, i+1) : ''
        })
    }

    if (this.currentYear === year && this.currentMonth === month) {
        let todayIndex = _.findIndex(this.dateArray, {
            year: this.currentYear,
            month: this.currentMonth,
            date: this.currentDate,
            isThisMonth: true
        })
        this.dateArray[todayIndex].isToday = true;
    }

    // Add the number of days next month to the array, with some months showing 6 weeks and some months showing 5 weeks
    if (this.dateArray.length % 7 !== 0) {
        let nextMonthAdd = 7 - this.dateArray.length % 7
        for (let i = 0; i < nextMonthAdd; i++) {
            if (month === 11) {
                this.dateArray.push({
                    year: year + 1,
                    month: 0,
                    date: i + 1,
                    isThisMonth: false,
                    isToday: false,
                    isSelect: false,
                    hasEvent: (this.isInEvents(year + 1, 0, i+1)) ? true : false,
                    eventClass: (this.isInEvents(year + 1, 0, i+1)) ? this.getEventClass(year + 1, 0, i+1) : ''
                })
            } else {
                this.dateArray.push({
                    year: year,
                    month: month + 1,
                    date: i + 1,
                    isThisMonth: false,
                    isToday: false,
                    isSelect: false,
                    hasEvent: (this.isInEvents(year, month+1, i+1)) ? true : false,
                    eventClass: (this.isInEvents(year, month+1, i+1)) ? this.getEventClass(year, month+1, i+1) : ''
                })
            }

        }
    }

    // All date data is now added to the dateArray array

    // Insert the date data into the new array every seven days
    for (let i = 0; i < this.dateArray.length / 7; i++) {
        for (let j = 0; j < 7; j++) {
            weekDays.push(this.dateArray[i * 7 + j]);
        }
        this.weekArray.push(weekDays);
        weekDays = [];
    }
  }

  public back() {

      let executeBack = () => {
          // Decrementing the year if necessary
          if (this.displayMonth === 0) {
              this.displayYear--;
              this.displayMonth = 11;
          } else {
              this.displayMonth--;
          }
          this.onMonthSelect.emit({
          'year': this.displayYear,
          'month': this.displayMonth
          });
          this.createMonth(this.displayYear, this.displayMonth);
      }
      if (this.startDate != null && moment.isDate(this.startDate)) {
          let month = this.startDate.getMonth();
          let year = this.startDate.getFullYear();
          if (!(this.displayYear == year && this.displayMonth == month)){
              let backDate = moment({year: this.displayYear, month: this.displayMonth, date: 1}).add(-1, "months");
              if (backDate.year() == year && backDate.month() == month) {
                  this.allowBack = false;
                  this.allowForward = true;
              } else {
                  this.allowBack = true;
                  this.allowForward = true;
              }
              executeBack();
          }
      } else {
          this.allowBack = true;
          this.allowForward = true;
          executeBack();
      }
      
  }

  public forward() {

      let executeForward= () => {
          // Incrementing the year if necessary
          if (this.displayMonth === 11) {
              this.displayYear++;
              this.displayMonth = 0;
          } else {
              this.displayMonth++;
          }
          this.onMonthSelect.emit({
          'year': this.displayYear,
          'month': this.displayMonth
          });
          this.createMonth(this.displayYear, this.displayMonth);
      }
    
      if (this.endDate != null && moment.isDate(this.endDate)) {
          let month = this.endDate.getMonth();
          let year = this.endDate.getFullYear();
          if (!(this.displayYear == year && this.displayMonth >= month)){
              let nextDate = moment({year: this.displayYear, month: this.displayMonth, date: 1}).add(1, "months");
              if (nextDate.year() == year && nextDate.month() == month) {
                  this.allowBack = true;
                  this.allowForward = false;
              } else {
                  this.allowBack = true;
                  this.allowForward = true;
              }
              executeForward();
          }
      } else {
          this.allowBack = true;
          this.allowForward = true;
          executeForward();
      }

      
  }

  // Select a day, click event
  public daySelect(day, i, j) {
      // First clear the last click status
      if (this.lastSelect < this.dateArray.length) {
          this.dateArray[this.lastSelect].isSelect = false;
      }
      // Store this clicked status
      this.lastSelect = i * 7 + j;
      this.dateArray[i * 7 + j].isSelect = true;

      this.onDaySelect.emit(day);
  }

}
