export default class SolicitudesModel {
    constructor(
        public id: number,
        public userId: string,
        public contenedorId: number,
        public tarifaId: number,
        public observacionesCliente: string,
        public estadoId: number,
        public empresaId: number,
        public lunesManiana: boolean,
        public lunesTarde: boolean,
        public martesManiana: boolean,
        public martesTarde: boolean,
        public miercolesManiana: boolean,
        public miercolesTarde: boolean,
        public juevesManiana: boolean,
        public juevesTarde: boolean,
        public viernesManiana: boolean,
        public viernesTarde: boolean,
        public sabadoManiana: boolean,
        public sabadoTarde: boolean,
        public domingoManiana: boolean,
        public domingoTarde: boolean,
        public alquilerContenedores : boolean,
        public frecuenciaDias: string,
        public numContenedores: string,
        public direccion : string,
        public respuesta : string,
        public zonaId: number,
        public zona: string,
        public recogidaDemanda: boolean
    ){
        
    }
    
}

export class SolicitudesListadoModel {
    constructor(
        public id: number,
        public userId: string,
        public contenedorId: number,
        public nombreUsuario: string,
        public tarifaId: number,
        public tarifaNombre: string,
        public observacionesCliente: string,
        public estadoId: number,
        public estadoNombre: string,
        public fecha: string,
        public empresaId: number,
        public lunesManiana: boolean,
        public lunesTarde: boolean,
        public martesManiana: boolean,
        public martesTarde: boolean,
        public miercolesManiana: boolean,
        public miercolesTarde: boolean,
        public juevesManiana: boolean,
        public juevesTarde: boolean,
        public viernesManiana: boolean,
        public viernesTarde: boolean,
        public sabadoManiana: boolean,
        public sabadoTarde: boolean,
        public domingoManiana: boolean,
        public domingoTarde: boolean,
        public alquilerContenedores : boolean,
        public frecuenciaDias: string,
        public numContenedores: string,
        public direccion : string,
        public respuesta : string,
        public zonaId: number,
        public zona: string,
        public recogidaDemanda: boolean,
        public ficheroFianzaUrl: string,
        public ficheroFianzaEstadoId: number,
        public ficheroCertificadoUrl?: string
    ){

    }
}
export class SolicitudesHomeModel {
        constructor(
            public id: number,
            public userId: string,
            public contenedorId: number,
            public nombreUsuario: string,
            public tarifaId: number,
            public tarifaNombre: string,
            public tarifaCategoria: string,
            public observacionesCliente: string,
            public estadoId: number,
            public estadoNombre: string,
            public fecha: string
        ){
    
        }
    
}