import EmpresaModel from "../empresas/empresa.model";

export default class UsuarioModel {
    constructor(
        public userId: string,
        public nombre: string,
        public apellidos: string,
        public username: string,
        public email: string,
        public dni: string,
        public telefono: string,
        public bloqueado: boolean,
        public empresa: EmpresaModel 
    ) {}
}
