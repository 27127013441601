import { Injectable } from '@angular/core';
import CategoriasListadoModel from '../models/categorias/categoria.model';
import UsuarioModel from '../models/usuario/usuario.model';
import EmpresaModel from '../models/empresas/empresa.model';
import ContenedorListadoModel from '../models/contenedores/contenedor.model';
import FacturasModel from '../models/facturas/facturas.model';
import ContratosModel from '../models/contratos/contratos.model';
import RegisterModel from '../models/register/register.model';
import { EstimacionModel } from '../api/estimaciones/estimacion.model';
import SolicitudesModel from '../models/solicitudes/solicitudes.model';


@Injectable({
  providedIn: 'root'
})
export class NavParamsService {
  

  constructor() { }

  usuario: UsuarioModel;
  categoria: CategoriasListadoModel;
  contenedor: ContenedorListadoModel;
  empresa: EmpresaModel;
  factura: FacturasModel;
  contrato: ContratosModel;
  registro: RegisterModel;
  estimacion: EstimacionModel;
  solicitud: SolicitudesModel;
  provinciaId: string;
  
  clear(): Promise<void> {
    
    this.usuario = null;
    this.categoria = null;
    this.contenedor = null;
    this.empresa = null;
    this.factura = null;
    this.contrato = null;
    this.registro = null;
    this.estimacion = null;
    this.solicitud = null;
    this.provinciaId = null;
    return;
  }
}
