import { Injectable } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { FromBottomAnimation } from '../animations/from-bottom.animation';
import { FromRightAnimation } from '../animations/from-rigth.animation';
import { ModalComponent } from '../components/modal/modal.component';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private modals: any[]

  constructor(
    public modalCtrl: ModalController,
    public popoverCtrl: PopoverController,
    private fromRightAnimation: FromRightAnimation,
    private fromBottomAnimation: FromBottomAnimation
  ) {
    this.modals = [];
   }

  async showModal(page: any, enableBackdropDismiss: boolean): Promise<any> {
    return new Promise(async (resolve, reject) => {   
      const modal = await this.modalCtrl.create({
        component: page,
        backdropDismiss: enableBackdropDismiss
      });

      await modal.present();
      GlobalService.modalPresented = true;
      this.modals.push(modal);

      modal.onDidDismiss()
        .then((data) => {
          GlobalService.modalPresented = false;
          let index = this.modals.indexOf(modal);
          if(index !== -1) {
            this.modals.splice(index, 1);
          }
          if (data.data !== undefined) {
            resolve(data.data);
          }
        });
    });
  }

  async showModalRight(page: any, enableBackdropDismiss: boolean): Promise<any> {
    return new Promise(async (resolve, reject) => {   
      const modal = await this.modalCtrl.create({
        component: page,
        enterAnimation: this.fromRightAnimation.enterAnimation,
        leaveAnimation: this.fromRightAnimation.leaveAnimation,
        backdropDismiss: enableBackdropDismiss,
        cssClass: ' modal-right'
      });

      await modal.present();
      GlobalService.modalPresented = true;
      this.modals.push(modal);

      modal.onDidDismiss()
        .then((data) => {
          GlobalService.modalPresented = false;
          let index = this.modals.indexOf(modal);
          if(index !== -1) {
            this.modals.splice(index, 1);
          }
          if (data.data !== undefined) {
            resolve(data.data);
          }
        });
    });
  }

  async showModalNavigation(page: any, enableBackdropDismiss: boolean): Promise<any> {
    return new Promise(async (resolve, reject) => {   
      const modal = await this.modalCtrl.create({
        component: ModalComponent,
        presentingElement: page,
        enterAnimation: this.fromBottomAnimation.enterAnimation,
        leaveAnimation: this.fromBottomAnimation.leaveAnimation,
        componentProps: {
          rootPage: page
        },
        backdropDismiss: enableBackdropDismiss
      });

      await modal.present();
      GlobalService.modalPresented = true;
      this.modals.push(modal);

      modal.onDidDismiss()
        .then((data) => {
          GlobalService.modalPresented = false;
          let index = this.modals.indexOf(modal);
          if(index !== -1) {
            this.modals.splice(index, 1);
          }
          if (data.data !== undefined) {
            resolve(data.data);
          }
        });
    });
  }

  async dismissAllModals(): Promise<void> {
    for (const modal of this.modals) {
      modal.dismiss('cancel');
    }
  }
  
}
