import { Injectable } from '@angular/core';
import { MainService } from '../main.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import ContratosModel, { LineasContratoModel } from 'src/app/models/contratos/contratos.model';
import ListaRequestModel from 'src/app/models/request.model.ts';
import { ListaRequestModelExtraId } from 'src/app/models/request.model.ts';


@Injectable({
  providedIn: 'root'
})
export class ContratosService {
  public service: MainService
  public timeoutCheckToken: any;

  constructor(public http: HttpClient) { 

  }

  public async getAllContratosByEmpresaId(listaRequest: ListaRequestModelExtraId): Promise<ContratosModel[]> {
    return await this.service.HttpPost('/contratos/postContratosByEmpresaId', listaRequest)
  }

  public async getAllLineasContratosByEmpresaId(contrato: ContratosModel): Promise<LineasContratoModel[]> {
    return await this.service.HttpPost('/contratos/postLineasContratosByEmpresaId', contrato)
  }

  public async postDescargarContrato(contrato: ListaRequestModelExtraId): Promise<string> {
    return await this.service.HttpPost('/contratos/postDescargarContrato', contrato)
  }

}
