import { Injectable } from '@angular/core';
import { MainService } from '../main.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import CategoriasListadoModel from 'src/app/models/categorias/categoria.model';
import ListaRequestModel from 'src/app/models/request.model.ts';
import { ListaRequestModelExtraId } from 'src/app/models/request.model.ts';
import ContenedorListadoModel from 'src/app/models/contenedores/contenedor.model';

@Injectable({
  providedIn: 'root'
})
export class TarifasService {
  public service: MainService
  public timeoutCheckToken: any;

  constructor(public http: HttpClient) { 

  }

  // contenedores agrupados por categorias, primero se llama a categorias  
  public async getAllCategorias(listaRequest: ListaRequestModel): Promise<CategoriasListadoModel[]> {
      return await this.service.HttpPost('/tarifas/getAllTarifas', listaRequest)
    }

  public async getAllTarifasOfContenedoresByCategoriaId(listaRequest: ListaRequestModelExtraId): Promise<ContenedorListadoModel[]> {
    return await this.service.HttpPost('/contenedores/getAllTarifasOfContenedoresByCategoriaId', listaRequest)
  }

  public async getTarifasOfContenedorByTarifaId(listaRequest: ListaRequestModelExtraId): Promise<ContenedorListadoModel> {
    return await this.service.HttpPost('/contenedores/getTarifasOfContenedorByTarifaId', listaRequest)
  }

}
