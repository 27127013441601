import { Injectable } from '@angular/core';
import { AnimationController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class FromRightAnimation {

    constructor(public animationCtrl: AnimationController) { }

    public enterAnimation = (baseEl: any) => {
        const backdropAnimation = this.animationCtrl.create()
            .addElement(baseEl.querySelector('ion-backdrop')!)
            .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

        const wrapperAnimation = this.animationCtrl.create()
            .addElement(baseEl.querySelector('.modal-wrapper')!)
            .keyframes([
                { offset: 0, opacity: '0.99', transform: 'translateX(0)' },
                { offset: 1, opacity: '1', transform: 'translate(-400px)' }
            ])
            .fromTo('transform', 'translateX(800px)', 'translateX(0px)');

        return this.animationCtrl.create()
            .addElement(baseEl)
            .easing('ease-out')
            .duration(350)
            .addAnimation([backdropAnimation, wrapperAnimation]);
    }

    public leaveAnimation = (baseEl: any) => {
        return this.enterAnimation(baseEl).direction('reverse');
    }
}