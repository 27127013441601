import { Injectable } from '@angular/core';
import { MainService } from '../main.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import ListaRequestModel from 'src/app/models/request.model.ts';
import { ListaRequestModelExtraId } from 'src/app/models/request.model.ts';
import SolicitudesModel from 'src/app/models/solicitudes/solicitudes.model';
import { SolicitudesListadoModel } from 'src/app/models/solicitudes/solicitudes.model';
import FicheroModel from 'src/app/models/ficheros/ficheros.model';
import { SolicitudesHomeModel } from 'src/app/models/solicitudes/solicitudes.model';

@Injectable({
  providedIn: 'root'
})
export class SolicitudesService {
  public service: MainService
  public timeoutCheckToken: any;

  constructor(public http: HttpClient) { 

  }

  public async enviarSolicitud(solicitud: SolicitudesModel): Promise<SolicitudesModel> {
    return await this.service.HttpPost('/solicitudes/enviarEmailSolicitud', solicitud)
  }

  public async getSolicitudes(listaRequest: ListaRequestModelExtraId): Promise<boolean> {
    return await this.service.HttpPost('/solicitudes/comprobarSolicitud', listaRequest)
  }

  public async getAllSolicitudesByEmpresaId(listaRequest: ListaRequestModelExtraId): Promise<SolicitudesListadoModel[]> {
    return await this.service.HttpPost('/solicitudes/postSolicitudesByEmpresaId', listaRequest)
  }

  public async getAllSolicitudesContratosByEmpresaId(listaRequest: ListaRequestModelExtraId): Promise<SolicitudesListadoModel[]> {
    return await this.service.HttpPost('/solicitudes/postSolicitudesContratosByEmpresaId', listaRequest)
  }

  public async getAllSolicitudesContratosByEmpresaIdHome(listaRequest: ListaRequestModelExtraId): Promise<SolicitudesHomeModel[]> {
    return await this.service.HttpPost('/solicitudes/postSolicitudesContratosByEmpresaIdHome', listaRequest)
  }

  public async getSolicitudById(id: string): Promise<SolicitudesListadoModel> {
    return await this.service.HttpPost('/solicitudes/getSolicitudById', {
      id
    })
  }

  public async enviarSolicitudContacto(solicitud: SolicitudesModel): Promise<boolean> {
    return await this.service.HttpPost('/solicitudes/enviarEmailSolicitudContacto', solicitud); 
  }

  public async postFinalizarServicio(servicio: SolicitudesListadoModel): Promise<boolean> {
    return await this.service.HttpPost('/solicitudes/postFinalizarServicio', servicio);
  }

  public async postComprobarEstadoServicio(servicio: SolicitudesListadoModel): Promise<boolean> {
    return await this.service.HttpPost('/solicitudes/postComprobarEstadoServicio', servicio);
  }

  public async subirDocumentacion(formData: FormData): Promise<FicheroModel> {
    return await this.service.HttpPostConFormData('/solicitudes/subirDocumentacion', formData)
  }

  public async deleteDocumento(fichero : FicheroModel): Promise<boolean> {
    return await this.service.HttpPost('/solicitudes/deleteDocumento', fichero)
  }

}
