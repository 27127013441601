import { Component } from '@angular/core';
import { AlertService } from './providers/alert.service';
import { MainService } from './api/main.service';
import { NavController } from '@ionic/angular';
import { ToastService } from './providers/toast.service';
import EmpresaModel from './models/empresas/empresa.model';
import { ListaRequestModelExtraId } from './models/request.model.ts';
import { NavParamsService } from './providers/nav-params.service';
import { LoaderService } from './providers/loader.service';
import { GlobalService } from './providers/global.service';
import { UtilService } from './providers/util.service';
import { JsonPipe } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [];
  public empresaVinculada : EmpresaModel;
  public vinculada : boolean;
  public labels = [];
  public version: string;
  public isMobile: boolean;
  public ready : boolean;
  public inicialNombre: string
  public inicialApellido : string
  public nombreCompleto : string
  public empresa : string
  constructor(private toastService: ToastService, private alertService: AlertService, public api: MainService, public navCtrl: NavController,
    private navParams: NavParamsService, private loaderService: LoaderService) {
      this.version = GlobalService.version;
      this.isMobile = true;
      this.init();
      this.ready = false;
      this.inicialNombre = "";
      this.inicialApellido = "";
      this.nombreCompleto = "";
      this.empresa = "";
    }

  async init() {
    await this.loaderService.show('Cargando...');
    GlobalService.isMobile = UtilService.mobileCheck();
    this.isMobile =  UtilService.mobileCheck();

    // try {
    //   this.orientationService.setOrientation(orientation);
    //   this.orientationService.lock(orientation);
    // } catch (error) {
    // }

    // Si se dispara antes uno de los eventos que ocultan la Splash que el DeviceReady la función no se ejecuta
    // correctamente así que ocultamos la Splash desde aquí.
    
    if (GlobalService.hideSplash) {
      GlobalService.hideSplash = true;
      UtilService.HideSplashPWA();
    }

    let update = false;
    // const versiones = await this.api.Versiones.getVersiones();
    // if (UtilService.isLengthGreatThan0(versiones)) {
    //   update = true;
    // }

    if (update) {
      // setTimeout(() => {
      //   UtilService.hardReloadPWA();
      // }, 10000);
    } else {
      setTimeout(() => {
        GlobalService.hideSplash = true;
        UtilService.HideSplashPWA();
      }, 2000);
    }

    GlobalService.getRecargarMenuObservable().subscribe(async () => {
      this.api.usuario;
      if (this.api.usuario != null && this.api.usuario != undefined){
        this.navParams.usuario = JSON.parse(JSON.stringify(this.api.usuario));
        if (this.api.usuario.empresa != null && this.api.usuario.empresa != undefined ) {
          this.navParams.empresa = JSON.parse(JSON.stringify(this.api.usuario.empresa));
          this.empresaVinculada = JSON.parse(JSON.stringify(this.api.usuario.empresa));
          this.inicialApellido = this.api.usuario.apellidos.substring(0,1).toUpperCase();
          this.inicialNombre = this.api.usuario.nombre.substring(0,1).toUpperCase();
          this.nombreCompleto = this.api.usuario.nombre + " " + this.api.usuario.apellidos;
          this.empresa = this.api.usuario.empresa.razon;
        }
      }
      
      if (this.empresaVinculada != null){
        let estado = Number(this.empresaVinculada.estadoId);
        if ( estado == 3 ) {
        this.vinculada = false;
        this.api.vinculacionEmpresa = true;
        } else {
        this.vinculada = true;
        this.api.vinculacionEmpresa = false;
        }
      }

    if (this.api.usuario != null && this.api.usuario != undefined){

      this.appPages = [
        { title: 'Inicio', url: '/home', icon: 'home', disabled: false},
        { title: 'Servicios contratados', url: '/servicios-contratados-listado', icon: 'settings', disabled: this.vinculada},
        { title: 'Facturas', url: '/facturas-listado', icon: 'document', disabled: this.vinculada},
        { title: 'Tarifas', url: '/tarifas-listado/'+'tarifas', icon: 'server', disabled: false},
        { title: 'Solicitudes', url: '/solicitudes-listado', icon: 'archive', disabled: this.vinculada},
        { title: 'Mi empresa', url: '/mi-empresa', icon: 'archive', disabled: false},
        { title: 'Mis usuarios', url: '/mis-usuarios', icon: 'settings', disabled: false}
        //{ title: 'Nuevo servicio', url: '/tarifas-listado/'+'servicios', icon: 'trash', disabled: this.vinculada}
      ];
  
      this.labels = [
        { title: 'Política de privacidad', url: '/politicas-privacidad',icon: 'settings', disabled: false},
        { title: 'Contacto', url: '/contacto',icon: 'settings', disabled: false},
      ];

    } else {

      // this.appPages = [
      //   { title: 'Inicio', url: '/home', icon: 'home', disabled: true},
      //   { title: 'Servicios contratados', url: '/servicios-contratados-listado', icon: 'settings', disabled: true},
      //   { title: 'Facturas', url: '/facturas-listado', icon: 'document', disabled: true},
      //   { title: 'Tarifas', url: '/tarifas-listado/'+'tarifas', icon: 'server', disabled: true},
      //   { title: 'Solicitudes', url: '/solicitudes-listado', icon: 'archive', disabled: true}
      //   //{ title: 'Nuevo servicio', url: '/tarifas-listado/'+'servicios', icon: 'trash', disabled: this.vinculada}
      // ];
  
      // this.labels = [
      //   { title: 'Política de privacidad', url: '/politicas-privacidad',icon: 'settings', disabled: false},
      //   { title: 'Contacto', url: '/contacto',icon: 'settings', disabled: false},
      // ];

    }
    
    //menú principal de esmasa 
    let pages = JSON.parse(JSON.stringify(this.appPages));
    this.appPages = [];
    for (let i = 0; i < pages.length; i++) {
      if (pages[i]) {
        this.appPages.push(pages[i]);
      }
    }

    //menú secundario: enlaces de contacto
    let labels = JSON.parse(JSON.stringify(this.labels));
    this.labels = [];
    for (let i = 0; i < labels.length; i++) {
      if (labels[i]) {
        this.labels.push(labels[i]);
      }
    }

    
    this.ready = true;


    });
    await this.loaderService.hide()
  }

  async logOut() {
    if (await this.alertService.showConfirm('', '¿Deseas cerrar sesión?', 'Cerrar sesión', 'Cancelar')) {
      try {
        await this.api.Login.logOut(true);
        this.navCtrl.navigateRoot('login-o-registro');
      } catch (ex) {
        this.toastService.showToastError('Error al cerrar sesion');
      }
    }
  }

  /*async getDatosEmpresa() {
    try {
      
      const listaRequest: ListaRequestModelExtraId = {
        start: 0,
        limit: 2,
        extraId: this.navParams.usuario.userId,
        filtroBusqueda: ""
      }

      // Lista las facturas según el id de la empresa asociada a ese usuario o según usuario id
        this.empresaVinculada = await this.api.empresas.getEmpresaByUserId(listaRequest)
    
    } catch (error) {
      this.toastService.showToastError(error.message);
    } finally {
      await this.loaderService.hide();
      return;
    }
  }*/

}
