import { Injectable } from '@angular/core';
import { AlertController, LoadingController, SpinnerTypes, ToastController } from '@ionic/angular';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  //#region Propiedades

  loader: HTMLIonLoadingElement;
  loaders: HTMLIonLoadingElement[];

  nombreAplicacion: string;

  //#endregion

  //#region Constructor

  constructor(
    public alertCtrl: AlertController,
    public loadingCtrl: LoadingController,
    public toastCtrl: ToastController,
  ) { 
    this.nombreAplicacion = environment.nombreAplicacion
    this.loaders = []
  }

  //#endregion

  //#region Alert

  showMessage(msg: string, _title?: string, textoOk?: string, callbackOnShow?: () => void): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.hideLoading()
        .then(() => {
          let title: string = this.nombreAplicacion;
          if (_title != undefined && _title != null && _title.length > 0) {
            title = _title;
          }
          let strOK: string = 'Ok';
          if (textoOk != undefined && textoOk != null && textoOk.length > 0) {
            strOK = textoOk;
          }

          this.alertCtrl.create({
            backdropDismiss: false,
            header: title,
            message: msg,
            buttons: [{
              text: strOK,
              role: "cancel",
              cssClass: "buttonOK",
              handler: () => {
                setTimeout(() => {
                  resolve('cancel');
                }, 0);
              }
            }]
          }).then(alerta => {
            alerta.present();
            if (callbackOnShow != null) {
              callbackOnShow();
            }
          })

        })
    })
  }

  showConfirm(msg: string, _title?: string, textoOK?: string, textoCancel?: string, classOk?: string, classCancel?: string, classAlerta?: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      classOk = classOk || "buttonOK"
      classCancel = classCancel || "buttonCancel"
      this.hideLoading()
        .then(() => {
          let title: string = this.nombreAplicacion;
          if (_title != undefined && _title != null && _title.length > 0) {
            title = _title;
          }
          let strOK: string = 'Sí';
          if (textoOK != undefined && textoOK != null && textoOK.length > 0) {
            strOK = textoOK;
          }
          let strCancel: string = 'No';
          if (textoCancel != undefined && textoCancel != null && textoCancel.length > 0) {
            strCancel = textoCancel;
          }
          this.alertCtrl.create({
            backdropDismiss: false,
            header: title,
            message: msg,
            cssClass: classAlerta,
            buttons: [
              {
                text: strCancel,
                role: 'cancel',
                cssClass: classCancel,
                handler: () => {
                  setTimeout(() => {
                    resolve(false);
                  }, 0)
                }
              },
              {
                text: strOK,
                cssClass: classOk,
                handler: () => {
                  setTimeout(() => {
                    resolve(true);
                  }, 0)
                }
              }
            ]
          }).then(alerta => {
            alerta.present();
          })
        })
    })
  }

  showPromptInput1(msg: string, _title?: string, textoOK?: string, textoCancel?: string, inputPlaceholder?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      this.hideLoading()
        .then(() => {
          let title: string = this.nombreAplicacion;
          if (_title != undefined && _title != null && _title.length > 0) {
            title = _title;
          }
          let strOK: string = 'Sí';
          if (textoOK != undefined && textoOK != null && textoOK.length > 0) {
            strOK = textoOK;
          }
          let strCancel: string = 'No';
          if (textoCancel != undefined && textoCancel != null && textoCancel.length > 0) {
            strCancel = textoCancel;
          }
          let strInputPlaceholder: string = '';
          if (inputPlaceholder != undefined && inputPlaceholder != null && inputPlaceholder.length > 0) {
            strInputPlaceholder = inputPlaceholder;
          }

          this.alertCtrl.create({
            backdropDismiss: false,
            header: title,
            message: msg,
            inputs: [
              {
                name: 'input1',
                placeholder: strInputPlaceholder
              }
            ],
            buttons: [
              {
                text: strCancel,
                role: 'cancel',
                cssClass: 'buttonCancel',
                handler: () => {
                  setTimeout(() => {
                    resolve(false);
                  }, 1)
                }
              },
              {
                text: strOK,
                cssClass: 'buttonOK',
                handler: data => {
                  setTimeout(() => {
                    resolve(data.input1)
                  }, 1)
                }
              }
            ]
          }).then((alerta) => {
            alerta.present();
          })

        })

    })
  }

  showPromptInput2(msg: string, _title?: string, textoOK?: string, textoCancel?: string, input1Placeholder?: string, input2Placeholder?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      this.hideLoading()
        .then(() => {
          let title: string = this.nombreAplicacion;
          if (_title != undefined && _title != null && _title.length > 0) {
            title = _title;
          }
          let strOK: string = 'Sí';
          if (textoOK != undefined && textoOK != null && textoOK.length > 0) {
            strOK = textoOK;
          }
          let strCancel: string = 'No';
          if (textoCancel != undefined && textoCancel != null && textoCancel.length > 0) {
            strCancel = textoCancel;
          }
          let strInput1Placeholder: string = '';
          if (input1Placeholder != undefined && input1Placeholder != null && input1Placeholder.length > 0) {
            strInput1Placeholder = input1Placeholder;
          }
          let strInput2Placeholder: string = '';
          if (input2Placeholder != undefined && input2Placeholder != null && input2Placeholder.length > 0) {
            strInput2Placeholder = input2Placeholder;
          }

          this.alertCtrl.create({
            backdropDismiss: false,
            header: title,
            message: msg,
            inputs: [
              {
                name: 'input1',
                placeholder: strInput1Placeholder
              },
              {
                name: 'input2',
                placeholder: strInput2Placeholder
              }
            ],
            buttons: [
              {
                text: strCancel,
                role: 'cancel',
                cssClass: 'buttonCancel',
                handler: () => {
                  setTimeout(() => {
                    resolve(false);
                  }, 1)
                }
              },
              {
                text: strOK,
                cssClass: 'buttonOK',
                handler: data => {
                  setTimeout(() => {
                    resolve(data.input1)
                  }, 1)
                }
              }
            ]
          }).then((alerta) => {
            alerta.present();
          })

        })

    })
  }

  //#endregion

  //#region Loading

  showLoading(msg?: string, _spinner?: SpinnerTypes): Promise<any> {
    return new Promise<void>( async (resolve, reject) => {

      let showLoader = ((content) => {
        this.hideLoading().then(() => {
          let spinner: SpinnerTypes = "bubbles";
          if (_spinner != undefined && _spinner != null && _spinner.length > 0) {
            spinner = _spinner;
          }
          this.loadingCtrl.create({
            spinner: spinner,
            message: content
          }).then((loader) => {
            this.loader = loader;
            this.loaders.push(this.loader);
            this.loader.present();
            setTimeout(() => {
              resolve();
            }, 1)
          });
        })
      })

      let content: string = 'Cargando...';
      if (msg != undefined && msg != null && msg.length > 0) {
        content = msg;
      }
      if (this.loader != undefined || this.loader != null) {
        if ((this.loader.message as string).trim() != content) {
          showLoader(content)
        } else {
          resolve();
        }
      } else {
        showLoader(content);
      }

    })
  }

  hideLoading(): Promise<any> {
    return new Promise<void>((resolve, reject) => {

      if (this.loaders.length > 0) {
        for (let loader of this.loaders) {
          setTimeout(() => {
            loader.dismiss();
          }, 1)
        }
        this.loaders = [];
        this.loader = null;
      }
      setTimeout(() => {
        resolve();
      }, 1);

    });
  }

  //#endregion

  //#region Toast

  showToast(message: string): Promise<any>{
    return new Promise<void>((resolve, reject) => {
      this.hideLoading()
        .then(() => {
          
          this.toastCtrl.create({
            message: message,
            duration: 3000,
            position: "top"
          }).then((toast) => {
            toast.present();
            setTimeout(() => {
              resolve(); // resolvemos cuando se oculta el toast.
            }, 3500);
          });

        })
    })
  }

  //#endregion

}
