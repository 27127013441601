import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-tabs-private',
  templateUrl: './tabs-private.component.html',
  styleUrls: ['./tabs-private.component.scss'],
})
export class TabsPrivateComponent implements OnInit {

  @Input() current: string;
  constructor(
    public navCtrl: NavController,
  ) {    
    if (this.current == undefined || this.current == null || this.current.trim().length == 0)  {
      this.current = 'home';
    }
  }

  ngOnInit() {
    
  }

  changeTab() {
    this.navCtrl.navigateRoot(this.current)
  }

}
