import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { MainService } from "src/app/api/main.service";
import { SolicitudesListadoModel } from "src/app/models/solicitudes/solicitudes.model";
import { AlertService } from "src/app/providers/alert.service";
import { LoaderService } from "src/app/providers/loader.service";

@Injectable({
    providedIn: 'root'
})
export class ServiciosContratadosDetalleResolver implements Resolve<SolicitudesListadoModel> {
    constructor(
        public api: MainService,
        private alertService: AlertService,
        private loaderService: LoaderService
    ) { }

    public async resolve(route: ActivatedRouteSnapshot): Promise<SolicitudesListadoModel> {
        try {
            await this.loaderService.show('Cargando...');
            let id = route.paramMap.get('id') || ''
            if (id != null && id != "" && id!="new") {
                return await this.api.solicitudes.getSolicitudById(id)
            } else {
                return new SolicitudesListadoModel(0, '', 0, '', 0, '', '', 0, '', '', 0, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, '', '', '', '', 0, '', false, '', 0)
            }
        } catch (ex: any) {
            if (ex != null && ex.message != null) {
                this.alertService.showMessage(ex.message, '¡Atención!')
            }
            throw ex
        } finally {
            await this.loaderService.hide()
        }
    }
}
