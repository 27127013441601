import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainService } from '../main.service';

@Injectable({
  providedIn: 'root'
})
export class WebPushService {

  public api: MainService;

  constructor(
    public http: HttpClient
  ) { }

  public async removeToken(token: string): Promise<void> {
    await this.api.HttpPost<void>('/webPush/removeToken', {
      token: token
    })
  }

  public async setPushToken(token: string): Promise<void> {
    await this.api.HttpPost<void>('/webPush/setPushToken', {
      token: token
    })
  }

}
